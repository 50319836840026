import { Injectable } from '@angular/core';
import { catchError, map } from 'rxjs/operators';
import { BehaviorSubject, Observable, throwError } from 'rxjs';
import { Router } from '@angular/router';
import { Storage } from '@ionic/storage';
import { Platform, ToastController } from '@ionic/angular';
import { HttpClientModule, HttpClient, HttpHeaders, HttpErrorResponse } from '@angular/common/http';
import { apiURL, appBuild } from '../common/global-constants'

@Injectable({
  providedIn: 'root'
})
export class AuthenticationService {

  authState = new BehaviorSubject(null);

  token;
  user: any;
  cliente: any;

  constructor(
    private router: Router,
    private storage: Storage,
    private platform: Platform,
    public toastController: ToastController,
    private http: HttpClient
  ) {
    this.platform.ready().then(() => {
      this.ifLoggedIn();
      this.getToken();
      this.getUser();
    });
  }

  logAppIni(deviceData, userData,): Observable<any> {
    if (userData && userData.id) {
      return this.http.post<any[]>(apiURL + "/log/app-init", { deviceData: deviceData, userId: userData.id, dominio: window.location.hostname })
        .pipe(
          catchError(error => {
            console.log('catchError logAppIni');
            console.log(error);
            return throwError(error.error);
          })
        );
    } else {
      return this.http.post<any[]>(apiURL + "/log/app-init", { deviceData: deviceData, dominio: window.location.hostname })
        .pipe(
          catchError(error => {
            console.log('catchError logAppIni apenas com deviceData');
            console.log(error);
            return throwError(error.error);
          })
        );
    }
  };

  ping(deviceData, userData, segundos): Observable<any> {
    if (userData && userData.id) {
      return this.http.post<any[]>(apiURL + "/app-ping", { deviceData: deviceData, userId: userData.id, segundos: segundos })
        .pipe(
          catchError(error => {
            console.log('catchError ping');
            console.log(error);
            return throwError(error.error);
          })
        );
    } 
  }

  async getUser() {
    return this.storage.get('blueconnect.user').then((result) => {
      // console.log('retorno getUser() com dados em  localstorage', result);
      return result;
    });
  }
 
  async getCliente() {
    return this.storage.get('blueconnect.cliente').then((result) => {
      // console.log('retorno getUser() com dados em  localstorage', result);
      return result;
    });
  }

  async getRecursos() {
    return this.storage.get('blueconnect.recursos').then((result) => {
      // console.log('retorno getRecursos() com dados em  localstorage', result);
      return result;
    });
  }

  sincUser(): Observable<any> {
    return this.http.get<any[]>(apiURL + "/perfil")
      .pipe(
        catchError(error => {
          return throwError(error.message || 'server error: sincUser failed');
        })
      )
  }

  getUid() {
    return this.storage.get('blueconnect.user').then((result) => {
      if (result) {
        return result.id;
      } else {
        return null;
      }
    });
  }
  
  getClienteId() {
    return this.storage.get('blueconnect.cliente').then((result) => {
      if (result) {
        return result.id;
      } else {
        return null;
      }
    });
  }

  getToken() {
    return this.storage.get('blueconnect.token').then((result) => {
      this.token = result;
      return result;
    });
  }

  ifLoggedIn() {
    this.storage.get('blueconnect.token').then((response) => {
      this.token = response;
      if (response) {
        this.authState.next(true);
      }
    });
  }

  storeLogin(token, user) {
    this.user = user;
    this.token = token;
    this.storage.ready().then(() => {
      let promises = [
        this.storage.set('blueconnect.token', token),
        this.storage.set('blueconnect.user', user),
        this.storage.set('blueconnect.recursos', user.cliente.recursos_app),
        this.storage.set('blueconnect.cliente', user.cliente),
      ];
      Promise.all(promises)
        .then(data => {
          this.router.navigate(['menu/first']);
          this.authState.next(true);
        });
    })
  }

  storeUser(user) {
    this.user = user;
    this.storage.ready().then(() => {
      let promises = [
        this.storage.set('blueconnect.user', user)
      ];
      Promise.all(promises)
        .then(data => {
          console.log('dados do usuário atualizados em localstorage');
          return true
        });
    })
  }
  
  storeCliente(cliente) {
    this.cliente = cliente;
    this.storage.ready().then(() => {
      let promises = [
        this.storage.set('blueconnect.cliente', cliente)
      ];
      Promise.all(promises)
        .then(data => {
          console.log('dados do cliente atualizados em localstorage');
          return true
        });
    })
  }

  /**
   * AUTENTICAÇÃO USANDO E-MAIL E SENHA
   */
  loginUser(email: string, password: string): Observable<any> {
    return this.http.post<any[]>(apiURL + "/login", { email: email, password: password })
      .pipe(
        catchError(error => {
          console.log('catchError loginUser');
          console.log(error);
          return throwError(error.error);
        })
      )
  }

  /**
   * AUTENTICAÇÃO USANDO LOGIN E SENHA
   */
  loginUserUsename(username: string, password: string): Observable<any> {
    return this.http.post<any[]>(apiURL + "/login", { username: username, password: password })
      .pipe(
        catchError(error => {
          console.log('catchError loginUser');
          console.log(error);
          return throwError(error.error);
        })
      )
  }

  signupUser(account: any): Observable<any> {

    const formData = new FormData();
    formData.append('name', account.name);
    formData.append('email', account.email);
    formData.append('cpf', account.cpf);
    formData.append('password', account.password);
    formData.append('confirm_password', account.confirm_password);

    return this.http.post(apiURL + "/register", formData)
      .pipe(
        catchError(error => {
          console.log('catchError signupUser');
          console.log(error);
          return throwError(error.error);
        })
      )
  }

  resetPassword(email: string): Observable<any> {
    return this.http.post<any[]>(apiURL + "/forgot-password", { email: email })
      .pipe(
        catchError(error => {
          console.log('catchError resetPassword');
          console.log(error);
          return throwError(error.error);
        })
      )
  }

  logoutUser(): Observable<any> {
    return;
  }

  logout() {
    this.token = '';
    this.storage.ready().then(() => {
      let promises = [
        this.storage.remove('blueconnect.token'),
        this.storage.remove('blueconnect.user'),
        this.storage.remove('blueconnect.cliente'),
        this.storage.remove('blueconnect.recursos')
      ];
      Promise.all(promises)
        .then(() => {
          this.router.navigate(['login']);
          this.authState.next(false);
        });
    })
  }

  isAuthenticated() {
    return this.authState.value;
  }
}
