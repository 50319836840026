import { Component } from '@angular/core';

import { Platform } from '@ionic/angular';
import { SplashScreen } from '@ionic-native/splash-screen/ngx';
import { StatusBar } from '@ionic-native/status-bar/ngx';
import { Router } from '@angular/router';
import { AuthenticationService } from './services/authentication.service';
import { interval } from 'rxjs';
import { pingInterval, appBuild } from './common/global-constants'

@Component({
  selector: 'app-root',
  templateUrl: 'app.component.html',
  styleUrls: ['app.component.scss']
})
export class AppComponent {
  constructor(
    private platform: Platform,
    private splashScreen: SplashScreen,
    private statusBar: StatusBar,
    private router: Router,
    private authService: AuthenticationService
  ) {
    this.initializeApp();
  }

  initializeApp() {
    console.log('APP INIT LOGGING build: ' + appBuild);
    this.platform.ready().then(async () => {

      let deviceData = this.platform.platforms();
      let userData = await this.getUser();

      //Envia um log pro servidor indicando a abertura do app na condição autenticada via localstorage.
      this.authService.logAppIni(deviceData, userData).subscribe(async (res: any) => {
        //através do domínio que está acessando recebe dados do servido 
        //para personalizar o app e grava em localstorage
        console.log('APP INIT RESPONSE', res);
        //grava os dados do cliente em localstorage
        if (res.cliente) {
          this.authService.storeCliente(res.cliente);
        }
      }, async (err: any,) => {
        console.log("erro recebido ao registrar inicialização do app");
        console.log(err);
      });

      this.statusBar.styleDefault();
      this.splashScreen.hide();

      this.authService.authState.subscribe(state => {
        if (state) {
          this.router.navigate(['menu/first'])
        } else {
          this.router.navigate(['login']);
        }
      });

  
      // //Envia o ping pro server
      const tempoSegundos = pingInterval;
      const source = interval(tempoSegundos * 1000);
      const subscribe = source.subscribe(val => this.ping(tempoSegundos));

    });
  }

  async ping(segundos) {
    let deviceData = this.platform.platforms();
    let userData = await this.getUser();
    if (userData && userData.id) {
      this.authService.ping(deviceData, userData, segundos).subscribe(async (res: any) => {
        //não faz nada!        
      }, async (err: any,) => {
        console.log("erro recebido ao registrar ping");
        console.log(err);
      });
    }
  }

  async getUser() {
    let me = await this.authService.getUser();
    return me;
  }
}
