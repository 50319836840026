import { NgModule } from '@angular/core';
import { PreloadAllModules, RouterModule, Routes } from '@angular/router';
import { AuthGuardService } from './services/auth-guard.service';
import { TutorialGuard } from './guards/tutorial.guard';

const routes: Routes = [
  { path: '', redirectTo: 'login', pathMatch: 'full' },
  {
    path: 'login',
    loadChildren: () => import('./pages/login/login.module').then( m => m.LoginPageModule),
    // canActivate: [TutorialGuard]
  },
  {
    path: 'register',
    loadChildren: () => import('./pages/register/register.module').then( m => m.RegisterPageModule)
  },
  {
    path: 'password-reset',
    loadChildren: () => import('./pages/password-reset/password-reset.module').then( m => m.PasswordResetPageModule)
  },
  {
    path: 'nova-empresa',
    loadChildren: () => import('./pages/nova-empresa/nova-empresa.module').then( m => m.NovaEmpresaPageModule)
  },
  {
    path: 'menu',
    loadChildren: () => import('./pages/menu/menu.module').then( m => m.MenuPageModule),
    canActivate: [AuthGuardService]
  },
  {
    path: 'conferencia-iframe',
    loadChildren: () => import('./pages/conferencia-iframe/conferencia-iframe.module').then( m => m.ConferenciaIframePageModule)
  },
  {
    path: 'chamado-detalhe',
    loadChildren: () => import('./pages/chamado-detalhe/chamado-detalhe.module').then( m => m.ChamadoDetalhePageModule)
  },
  {
    path: 'tutorial',
    loadChildren: () => import('./pages/tutorial/tutorial.module').then( m => m.TutorialPageModule)
  },
  {
    path: 'suporte',
    loadChildren: () => import('./pages/contato/contato.module').then( m => m.ContatoPageModule)
  },
  {
    path: 'conteudo-interna',
    loadChildren: () => import('./pages/conteudo-interna/conteudo-interna.module').then( m => m.ConteudoInternaPageModule)
  },
  {
    path: 'modal-networking-agendar',
    loadChildren: () => import('./pages/modal-networking-agendar/modal-networking-agendar.module').then( m => m.ModalNetworkingAgendarPageModule)
  },
  
];

@NgModule({
  imports: [
    RouterModule.forRoot(routes, { preloadingStrategy: PreloadAllModules })
  ],
  exports: [RouterModule]
})
export class AppRoutingModule { }
