export const appVersion: string = "1.1.10";
export const appBuild: string = "44";

export const apiURL: string = "https://bc.blue4u.app/api/v1";

export const storageURL: string = "https://blueconnect.s3.us-east-2.amazonaws.com/";

export const chatURL:string  = "http://localhost:3001";

export const pingInterval = 180;
