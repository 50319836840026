import {
    HttpRequest,
    HttpHandler,
    HttpEvent,
    HttpInterceptor,
    HttpResponse,
    HttpErrorResponse
} from '@angular/common/http';
import { Observable, throwError } from 'rxjs';
import { map, catchError } from 'rxjs/operators';
import { Injectable } from '@angular/core';
import { LoadingController } from '@ionic/angular';
import { AuthenticationService } from './services/authentication.service';

@Injectable()
export class HttpConfigInterceptor implements HttpInterceptor {
    loaderToShow: any;

    constructor(
        public loadingController: LoadingController,
        private authService: AuthenticationService
    ) {
        this.getToken();
    }

    async getToken() {
        return await this.authService.getToken();
    }

    intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {

        const token = this.authService.token;
        let t = "Bearer" + ' ' + token;

        //Authentication by setting header with token value
        if (token) {
            request = request.clone({
                setHeaders: {
                    'Authorization': t
                }
            });
        }

        if (!request.headers.has('Content-Type')) {
            // request = request.clone({
            //     setHeaders: {
            //         'Content-Type': 'application/json'
            //     }
            // });
        }

        request = request.clone({
            // headers: request.headers.set('Accept', 'application/json')
        });

        return next.handle(request).pipe(
            map((event: HttpEvent<any>) => {
                if (event instanceof HttpResponse) {
                    // console.log('event--->>>', event);
                }
                return event;
            }),
            catchError((error: HttpErrorResponse) => {
                console.error(error);
                return throwError(error);
            })
        );
    }


}